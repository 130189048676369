import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import { useQueryParam, StringParam } from 'use-query-params'
import OrderReceived from '../components/OrderReceived'
import { apiLocation } from '../molecules/CheckoutComponent/const'
import SEO from '../components/seo'

const OrderReceivedPage = () => {
  const [orderParams] = useQueryParam('order', StringParam)
  const [orderNumber, setOrderNumber] = useState(null)
  const [orderKey, setOrderKey] = useState(null)
  const [orderDetails, setOrderDetails] = useState(null)

  useEffect(() => {
    const [orderNumber, orderKey] = orderParams.split(',')
    setOrderNumber(orderNumber)
    setOrderKey(orderKey)
  }, [orderParams, orderKey])

  useEffect(() => {
    orderNumber && fetch(`${apiLocation}/thankyou`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderNumber,
        orderKey
      }),
    })
      .then(res => res.json())
      .then(data => setOrderDetails(data))
      .catch(error => {
        console.error('Error:', error)
      })
  }, [orderNumber])

  return (
    <Layout hideTrustedShopsBadge={!orderDetails?.orderNumber} >
      <SEO title={orderNumber ? `Order Received #${orderNumber}` : 'Order Received'} />
      {orderDetails && <OrderReceived
        orderDetails={orderDetails}
      />}
    </Layout >
  )
}

export default OrderReceivedPage
