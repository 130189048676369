import React from 'react'
import RegularTypo from '../../typography/RegularTypo'
import BiggerTypo from '../../typography/BiggerTypo'
import SmallerTypo from '../../typography/SmallerTypo'
import styled from 'styled-components'
import { formatPriceReceived } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { tychoNavy, mainBackgroundGray } from '../../utils/theme'

const StyledOrderTotal = styled.div`
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  padding-top: 13px;
  padding-bottom: 14px;
  margin-top: 16px;
  margin-bottom: 10px;
`

const OrderTotal = ({
  orderTotal,
  shippingCost,
  subtotal,
  vatInfo,
  discount,
  currency
}) => {
  const { t } = useTranslation()

  return (
    <StyledOrderTotal>
      {discount ?
        <RegularTypo
          color={mainBackgroundGray}
          style={{ backgroundColor: tychoNavy, textAlign: 'center', marginBottom: 10 }}
          text={`Rabat na produkty: ${discount}%`}
        />
        : null}
      <RegularTypo
        text={`${t('checkout::cart_subtotal')}: ${formatPriceReceived({ price: subtotal, currency })}`}
      />
      <RegularTypo
        text={`${t('checkout::shipping_total')}: ${formatPriceReceived({ price: shippingCost, currency })}`}
      />
      <BiggerTypo style={{ fontSize: 18, margin: '6px 0 0' }}>
        {`${t('checkout::order_total')}: ${formatPriceReceived({ price: orderTotal, currency })}`}
      </BiggerTypo>
      <SmallerTypo
        grey
        style={{ fontSize: 12 }}
        text={`${t('checkout::incl_vat')} ${vatInfo}`}
      />
    </StyledOrderTotal>
  )
}

export default OrderTotal
